.support-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
    padding-bottom: 120px;
}

.support-page_title {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
}

.support-page_context {
    font-size: 18px;
    text-indent: 15px;
}

.support_link {
    display: block;
    box-sizing: border-box;
    text-align: center;

}