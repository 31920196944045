.balance-plate {
    padding: 25px;
    border-radius: 20px;
    border: solid 2px var(--text-grey);
    display: flex;
    justify-content: space-between;
}

.balance-plate_leftCol {
    max-width: 70%;
}

.balance-plate_leftCol>div {
    display: flex;
    flex-direction: column;
    gap: 10px
}
.status-div {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
.balance-plate_leftCol>div>span:first-child {
    font-size: 22px;
    font-weight: 600;
}
.balance-plate-edit-img {
    width: 18px;
    height:18px;
    border-radius: 13px;
    border: none;
    background-image: var(--main-grad);
    padding: 14px;
}