.my-projects {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
    padding-bottom: 120px;
}
.my-projects_header {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
}

.my-project_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.my-projects_list {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 25px;
}