
.mainFraim_lastActiviti-body-element:hover {
    border: solid 2px var(--small-btn-bg);
}

.mainFraim_lastActiviti-body-element {
    border: solid 2px transparent;
}

.mainFraim_lastActiviti-body {
    gap: 15px;
}

.mainFrame_lastActiviti-header {
    display: flex;
    justify-content: space-between;
    gap:5px;
    padding: 0 20px;
    align-items: baseline;
    margin-bottom: 12px;
}
.mainFrame_lastActiviti-header-sort {
    display: flex;
    justify-content: flex-end;
    gap:15px;
    align-items: baseline;
}

.mainFrame_lastActiviti-header-sort>a {
    font-size: 14px;
    color: var(--text-grey);
    transition: 0.3s ease-in-out;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    @media(max-width:400px){
        font-size: 12px;
    }
}

.mainFrame_lastActiviti-header>a:nth-child(1) {
    font-size: 22px;
    font-weight: 400;
    border-bottom: 2px solid transparent;
    @media(max-width:410px){
        font-size: 16px;
    }
}

.mainFrame_lastActiviti-header-sort>a:hover {
    cursor: pointer;
    font-size: 16px;
    @media(max-width:400px){
        font-size: 13px;
    }
    border-bottom: 2px solid transparent;
    
}
.active {
    
    font-size: 18px !important;
    border-bottom: 2px solid;
    background-clip: text !important;
    background: var(--main-grad);
    color: transparent !important;
    border-image: 1 linear-gradient(100.57deg, #735eaf 12.93%, #FFA37B 121.02%);
    @media(max-width:400px){
        font-size: 14px !important;
    }
}