.mainFrame_lastActiviti-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 15px;
    padding:30px 0px 0px 0px;
}

.mainFraim_lastActiviti-body-element:hover {
    border: solid 2px var(--small-btn-bg);
}

.mainFraim_lastActiviti-body-element {
    border: solid 2px transparent;
}

.mainFrame_lastActiviti-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}
.mainFrame_lastActiviti-sort{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:15px;   
    height: 20px;
}
.mainFrame_lastActiviti-sort>a {
    font-size: 14px;
    color: var(--text-grey);
    transition: 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    padding: 0px 5px;
}
.mainFrame_lastActiviti-sort>a:hover{
    font-size: 16px;
}
.mainFrame_lastActiviti-header-alter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.mainFrame_lastActiviti-header>div:nth-child(1) {
    font-size: 18px;
    font-weight: 400;
    
}

.mainFrame_lastActiviti-header>div:nth-child(2) {
    font-size: 14;
    color: var(--text-grey);
}

.mainFrame_lastActiviti-body {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.mainFraim_lastActiviti-body-element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap:15px;
}

.image-body-element {
    background-color: var(--small-btn-bg);
    padding: 4px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
}

.mainFraim_lastActiviti-body-leftcol {
    display: flex;
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: auto;
    justify-content: start;
    align-items: center;
    gap: 12px;
    width: fit-content;
}

.mainFraim_lastActiviti-body-leftcol>span {
    display: flex;
    height: 100%; 
    flex-direction: column;
    justify-content: start;
    align-items: start;
    font-size: 16px;
    
}
.mainFraim_lastActiviti-body-leftcol>span>:nth-child(1) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.mainFraim_lastActiviti-body-leftcol>span>p:first-child{
        
}

.mainFraim_lastActiviti-body-leftcol>span>p:nth-child(2) {
    font-size: 11px;
    font-weight: 800;
    color: var(--text-grey);
}

.activity-subs-price {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    text-align: end;
    width: fit-content;
    min-width: 20%;
    flex-grow: 10;
    >span{
        width: 100%;
        text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    }
    @media(max-width:350px){
        min-width: 50px;
    }
}

.active-calendar {
    padding: 8px;
    border-radius: 14px;
    background-color: var(--small-btn-bg);
}

.lastActivety-footer {
    padding: 10px 20px;
    position: fixed;
    bottom: 3%;
    z-index: 10;
    box-sizing: border-box;
    text-align: center;
    width: auto !important;
}

.lastActivety-footer-btn {
    text-align: center;
    display: block;
}

/* Extra */
.mainFrame_header-extra:nth-child(1) {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: inline-block;
    width: max-content;
    background-clip: text;
    width: fit-content;
    @media(max-width:360px){
        font-size: 13px;
    }
}
.calendar-wrapper {
    width: 95dvw !important;
    max-width: 550px !important;
    position: absolute;
    right: auto;
    top: 90px;
    background: var(--element-bg) !important;
    border: solid 1px var(--text-grey) !important;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.calendar-btn {
    text-align: center;
    width: auto !important;
    margin-bottom: 10px;
}
.btn-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap:20px;
    
    button{
        border-color: #a2a2a2 ;
    }
    
    button:disabled{
        color: var(--element-bg);
        border-color: var(--element-bg);
    }
}
.single-btn{
    width: 100%;
}