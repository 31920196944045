.editing-project-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 30px;
    padding-bottom: 120px;
}

.editing-project-page_title {
    text-align: left;
    font-weight: 600;
    background-image: var(--main-grad);
    color: transparent;
    display: flex;
    width: max-content;
    background-clip: text;
    width: fit-content;
    white-space: nowrap;
}

.editing-project-page_content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.edit-project-page_description {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.edit-project-page_description>p {
    font-size: 22px;
}

.edit-description {
    text-decoration: underline;
    color: var(--text-grey);
}

.edit-project-page_link {
    display: flex;
    flex-direction: column;
    gap: 13px;    
}

.edit-project-page_link>p {
    font-size: 22px;
}

.edit-project-link {
    display: block;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-decoration: underline;
    text-align: left;
    word-break: break-all;
}

.edit-project_status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 18px;
}

.edit-project_status>p {
    font-size: 22px;
}

.edit-project_status_active {
    color: green;
    font-size: 16px;
    font-weight: 400;
    background-image: black;
}
.editing-project-page_title-wrapper {
    display: flex;
    flex-wrap: nowrap;
}
.edit-project_status_pending {
    color: var(--text-grey)
}


.edit-project_api>p,
.edit-project_key>p,
.edit-project_token>p {
    font-size: 22px;
}

.edit-project_api>span {
    word-break: break-all;
}

.edit-project_api,
.edit-project_key,
.edit-project_token,
.edit-project_buttons {
    word-break: break-all;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.edit-project_token-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
}
.edit-project_token_warn {
    display: block;
    font-size: 14px;
    color: var(--text-grey);
}
.edit-project_button {
    border-radius: 20px;
    border: 1px solid var(--text-grey);
    background-color: transparent;
    padding: 10px 0px;
    text-align: center;
    font-size: 20px;
}