/*Calendar*/
/*тело*/
.lastActivety-calendar {
    width: 95dvw !important;
    max-width: 550px !important;
    margin: 0px auto;
    font-size: 20px;
    font-family: var(--type3) !important;
    background: var(--element-bg) !important;
    border: none 1px var(--text-grey) !important;
    border-radius: 8px;
}

/*число сейчас*/
.react-calendar__tile--now {
    background-color: var(--body-bg) !important;
    border: solid 1px var(--text-grey) !important;
    border-radius: 8px;
}

/*заголовок навигационной панели*/
.react-calendar__navigation__label {
    background-color: grey !important;
    border-radius: 8px !important;
}

/*ховер эффект даты месяца календаря*/
.react-calendar__tile:enabled:hover {
    border-radius: 8px !important;
    background-color: var(--small-btn-bg);
}

/*дата начала отсчета и конца*/
.react-calendar__tile--active:enabled:focus {
    background: var(--small-btn-bg) !important;
}

/*ховер выделение  От и До*/
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    border-radius: 8px !important;
    background-color: var(--small-btn-bg) !important;
}

.react-calendar__tile--active {
    border-radius: 8px;
    background: var(--text-grey) !important;
    color: white;
}

.react-calendar__tile--hover {
    background-color: var(--text-grey) !important;
    border-radius: 8px;
}

.react-calendar__navigation__label {
    text-transform: capitalize;
}

/*тело с цифрами месяца*/
.react-calendar__month-view__days {
    gap: 3px 1px;
    justify-content: space-around;
    padding-bottom: 6px;
}

/*елемент , день, тела месяца с цифрами*/
.react-calendar__month-view__days__day {
    max-width: 13% !important;
}

/*ховер эффект навигации календаря*/
.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
    background-color: var(--text-grey) !important;
    border-radius: 8px;
}

.react-calendar__year-view__months__month {
    padding: 4px !important;
}

.react-calendar__tile--hasActive {
    border-radius: 8px;
    background-color: inherit !important;
    border: 1px solid var(--text-grey) !important;
}

.react-calendar__navigation>button {
    background: inherit !important;
}

.calendar__year-view__months__month {
    background-color: inherit !important;
}

.react-calendar__year-view__months__month::before {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}

.react-calendar__decade-view__years__year {
    padding: 4px !important;
}

.react-calendar__century-view__decades__decade {
    padding: 4px !important;
}

.react-calendar__century-view__decades__decade::after {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}

.react-calendar__century-view__decades__decade::before {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}

.react-calendar__year-view__months__month::after {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}

.react-calendar__decade-view__years__year::before {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}

.react-calendar__decade-view__years__year::after {
    content: "";
    display: block;
    padding: 6px;
    position: relative;
}