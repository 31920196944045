.scroll-down-bg {
    top: 0;
    left: 0;
    width: 100dvw;
    position: fixed;
    height: 100dvh;
    z-index: 20;
    justify-content: start;
    background-color: #0000008e;
    backdrop-filter: blur(10px);
    overflow: hidden;
}

.scroll-down-close {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

}

.scroll-down-close>span {
    margin-right: 20px;
    font-size: 45px;
    font-weight: 600;
    transform: rotate(45deg);
}

.scroll-down {
    font-size: 30px;
    width: 100%;
}

.arrow-visual-up {
    width: 12px;
    transform: rotate(180deg);
}

.arrow-visual-down {
    width: 12px;
}

.scroll-down-activator {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 2px var(--text-grey);
}

.scroll-down-list {
    position: relative;
    overflow-y: scroll;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 30px;
    margin: 0;
    padding: 0px 20px;
    font-size: 32px;
    height: calc(80dvh - 60px);
    top: 40%;
    animation: 0.8s forwards fadein;
}

.scroll-down-filter-list>span {
    padding: 10px 4px;
    border-radius: 20px;
    border: 2px solid var(--text-grey);
    text-align: center;
    box-sizing: border-box;
    text-align: center;
    background: linear-gradient(130deg, #0400f021 0%, #e270ff21 60%, #efadff21 100%);
}

.scroll-down-filter-list {
    margin-left: 20px;
    width: calc(100% - 40px);
    box-sizing: border-box;
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-bottom: 20px;
    display: flex;
    font-size: 18px;
    justify-content: start;
    gap: 4px 10px;
    top: 40%;
    animation: 0.8s forwards fadein;
}

@keyframes fadein {
    0% {
        transform: scale(0.65);
        opacity: 0.5
    }

    100% {
        top: 0;
        transform: scale(1);
        opacity: 1
    }
}