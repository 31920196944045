.mainFrame_balance-page {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.mainFrame_balance-page-utility {
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    /* border: solid 1px rgb(53, 48, 20); */
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    background-image: url(../../img/bgSprite3.png);
}

.balance-page-utility_left,.balance-page-utility_right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 20px;
}

.balance-page-utility_left>div,.balance-page-utility_right>div {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
}
.balance-page-utility_right {
    align-items: end;
    width: 50%;
    justify-content: end;
}
.balance-page-utility_right>div:last-child {
    width: 70%;
    align-items: start;
}
.img-eye {
    width: 45px;
    height: 45px;
}
.mainFraim_balance-balance {
    font-size: 16px;
    font-weight: 400;
}
.mainFraim_balance-total {
    font-size: 35px;
    font-weight: 700;
}
.mainFraim_balance-total-alter {
    font-size: 22px;
    font-weight: 700;
}
/* Extra */
/* BalancePlate */